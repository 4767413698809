// export const scFaults = [
//     {title: "Displayschutzfolie anbringen", value: '12,34', item_no: 'DL-SUPPORT'},
//     {title: "Komplette Reinigung von iPhone, iPad, iPod", value: '14,90', item_no: 'DL-CLEAN'},
//     {title: "iPhone, iPad Datensicherung oder Datenwiederherstellung", value: '29,90',item_no: 'DL-DATENSICH'},
//     {title: "E-Mail-Account einrichten", value: '29,90', item_no: 'DL-MAIL'},
//     {title: "Apple ID einrichten, Apple ID zurücksetzen", value: '34,90', item_no:'DL-APPLEID'}, 
//     {title: "Datenübertragung auf iPhone, iPad, iPod", value: '39,90', item_no: 'DL-DATATRANSFER-IOS'},
//     {title: "Mac Time Machine Datensicherung", value: '44,90', item_no: 'DL-BACKUP-MACOS'}, 
//     {title: "OS X Installation", value: '69,90', item_no: 'DL-MAC'},
//     {title: "Datenübertragung auf Mac von bestehendem Time Machine Backup", value: '69,90', item_no: 'DL-DATATRANS-MAC-EXT'}, 
// ]

export const scFaults = [
    {title: "Displayschutz anbringen", value: '7,99', item_no: 'DL-FOLIE'},
    {title: "Hardware Startklar", value: '19,99', item_no: 'DL-READY'},
    {title: "Reinigung iPhone, iPad", value: '14,99', item_no: 'DL-CLEAN'},
    {title: "Email einrichten", value: '34,99', item_no: 'DL-MAIL'},
    {title: "iOS Wiederherstellen", value: '34,99', item_no: 'DL-RESTORE-IOS'},
    {title: "macOS Install", value: '69,99', item_no: 'DL-INSTALL-MACOS'},
    {title: "Reinigung Mac", value: '29,99', item_no: 'DL-CLEAN-MAC'},
    {title: "iOS Datensicherung", value: '29,99', item_no: 'DL-DATENSICH'},
    {title: "iOS Datenübertragung", value: '49,99', item_no: 'DL-DATATRANSFER-IOS'},
    {title: "APPLE ID Restore", value: '49,99', item_no: 'DL-APPLEID'},
    {title: "macOS Datensicherung", value: '69,99', item_no: 'DL-BACKUP-MACOS'},
    {title: "macOS Datenübertr.", value: '119,99', item_no: 'DL-DATATRANSFER-MAC'},
]