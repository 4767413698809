<div class="container-fluid">
    <div class="navbar fixed-top">
        <div class="header-primary">
            <!-- breadcrumbs -->
            <ol class="breadcrumb">
                <li class="breadcrumb-item active">
                    <app-breadcrumb></app-breadcrumb>
                </li>
            </ol>
            <!-- user -->
            <div class="nav-user">
                <div class="nav-item dropdown notifications-icon">
                    <a class="nav-link d-flex align-items-center" id="navbarDropdownMenuLink" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <mat-icon class="material-symbols-outlined notification-icon">notifications</mat-icon>
                        <span class="notification" *ngIf="totalServiceCases > 0">1</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink" *ngIf="totalServiceCases > 0">
                        <a class="dropdown-item" routerLink="/service-cases"
                            [queryParams]="{ nav_code: 2 }">{{totalServiceCases}} Service cases are more then two days
                            in status ANFANG</a>
                    </div>
                </div>
                <div class="nav-user-spacer"></div>
                <div class="nav-user-profile">
                    <a class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{currentUser.first_name}} {{currentUser.last_name}}
                    </a>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-pointer-header"
                        aria-labelledby="dropdownMenuLink">
                        <a class="dropdown-item" (click)="logout()">
                            <span class="material-symbols-outlined">logout</span>
                            Sign out
                        </a>
                    </div>
                </div>
                <div class="avatar">
                    <span> {{currentUser | initials}}</span>
                </div>
            </div>
        </div>
        <!-- header -->
    </div>
</div>