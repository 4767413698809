<div class="sidebar">
  <a routerLink="/">
    <div class="sidebar-brand"></div>
  </a>
  
  <!-- Navigation -->
  <div class="sidebar-nav" *ngFor="let image of images">

    <div class="nav-group">
      <ul>
        <li
          class="nav-item expandable-item"
          [class.expanded]="isExpanded['servicedesk']"
          *ngIf="('search-device' | checkAccess) || ('dashboard' | checkAccess)"
        >
          <a
            routerLinkActive="active"
            #rla="routerLinkActive"
            (click)="toggleSubMenu($event, 'servicedesk')"
            class="dropdown-item"
          >
            <mat-icon class="material-symbols-outlined nav-icon"
              >screen_search_desktop</mat-icon
            >
            <span class="nav-label expandable-label">
              <span class="servicedesk">
                <mat-icon class="material-symbols-outlined icon">screen_search_desktop</mat-icon>
                Servicedesk
              </span>
              <span class="arrow-container">
                <mat-icon
                  class="arrow-icon"
                  [class.expanded]="isExpanded['servicedesk']"
                  >expand_more</mat-icon
                >
              </span>
            </span>
          </a>
          <!-- Submenu for Helpdesk -->
          <ul class="submenu">
            <li class="nav-item" *ngIf="('dashboard' | checkAccess)">
              <a
                [routerLink]="['/dashboard']"
                routerLinkActive="active"
                #rlaDashboard="routerLinkActive"
              >
                <mat-icon class="material-symbols-outlined nav-icon"
                  >dashboard</mat-icon
                >
                <span class="nav-label ml-2">
                  <mat-icon class="material-symbols-outlined icon"
                    >dashboard</mat-icon
                  >
                  Dashboard
                </span>
              </a>
            </li>
            <li class="nav-item" *ngIf="('search-device' | checkAccess)">
              <a
                [routerLink]="['/search-device']"
                routerLinkActive="active"
                #rlaSearchDevice="routerLinkActive"
              >
                <mat-icon class="material-symbols-outlined nav-icon"
                  >devices</mat-icon
                >
                <span class="nav-label ml-2">
                  <mat-icon class="material-symbols-outlined icon"
                    >devices</mat-icon
                  >
                  Service Device
                </span>
              </a>
            </li>
          
          
          </ul>
        </li>
        <li class="nav-item" *ngIf="('service-cases' | checkAccess)">
          <a
            [routerLink]="['/service-cases']"
            routerLinkActive="active"
            #rlaServiceCases="routerLinkActive"
          >
            <mat-icon class="material-icons-outlined nav-icon"
              >edit_doucment</mat-icon
            >
            <span class="nav-label">
              <mat-icon class="material-symbols-outlined icon"
                >edit_document</mat-icon
              >
              Service Cases
            </span>
          </a>
        </li>
      </ul>
    </div>
    <div class="nav-group mb-0">
      <ul>
        <li class="nav-item" *ngIf="('clients-list' | checkAccess)">
          <a
            [routerLink]="['/clients-list']"
            routerLinkActive="active"
            #rlaClients="routerLinkActive"
          >
            <mat-icon class="material-symbols-outlined nav-icon"
              >group</mat-icon
            >
            <span class="nav-label">
              <mat-icon class="material-symbols-outlined icon">group</mat-icon>
              Clients
            </span>
          </a>
        </li>
        <li class="nav-item" *ngIf="('users-list' | checkAccess)">
          <a
            [routerLink]="['/users-list']"
            routerLinkActive="active"
            #rlaManagers="routerLinkActive"
          >
            <mat-icon class="material-symbols-outlined nav-icon"
              >badge</mat-icon
            >
            <span class="nav-label">
              <mat-icon class="material-symbols-outlined icon">badge</mat-icon>
              Users
            </span>
            <span
              *ngIf="('form/:id' | checkAccess)"
              class="badge badge-pill"
              role="button"
              tabindex="0"
              (click)="onNavigate($event, '/users-list/form/new')"
            >
              <span class="material-icons-outlined"> add </span>
            </span>
          </a>
        </li>
      </ul>
    </div>
    <div class="nav-group mb-0">
      <ul>
        <li class="nav-item" *ngIf="('stores-list' | checkAccess)">
          <a
            [routerLink]="['/stores-list']"
            routerLinkActive="active"
            #rlaStores="routerLinkActive"
          >
            <mat-icon class="material-symbols-outlined nav-icon"
              >store</mat-icon
            >
            <span class="nav-label">
              <mat-icon class="icon">store</mat-icon>
              Stores</span
            >
            <span
              *ngIf="('form/:id' | checkAccess)"
              class="badge badge-pill"
              role="button"
              tabindex="0"
              (click)="onNavigate($event, '/stores-list/form/new')"
            >
              <span class="material-icons-outlined"> add </span>
            </span>
          </a>
        </li>
      </ul>
    </div>
    <div class="nav-group">
      <ul>
        <li class="nav-item" *ngIf="('diagnostics' | checkAccess)">
          <a
            [routerLink]="['/diagnostics']"
            routerLinkActive="active"
            #rlaDiagnostic="routerLinkActive"
          >
            <mat-icon class="material-symbols-outlined nav-icon"
              >earthquake</mat-icon
            >
            <span class="nav-label">
              <span class="material-symbols-outlined icon"> earthquake </span
              >Diagnostics
            </span>
          </a>
        </li>
        <li class="nav-item" *ngIf="('device-tool' | checkAccess)">
          <a
            [routerLink]="['/device-tool']"
            routerLinkActive="active"
            #rlaDeviceTool="routerLinkActive"
          >
            <mat-icon class="material-symbols-outlined nav-icon"
              >phonelink_setup</mat-icon
            >
            <span class="nav-label">
              <mat-icon class="material-symbols-outlined icon"
                >phonelink_setup</mat-icon
              >
              Device Tool
            </span>
          </a>
        </li>
        <li class="nav-item" *ngIf="('enrollment' | checkAccess)">
          <a
            class="pointer"
            (click)="onRedirectToDEP()"
          >
            <mat-icon class="material-symbols-outlined nav-icon"
              >app_registration</mat-icon
            >
            <span class="nav-label">
              <mat-icon class="material-symbols-outlined icon"
                >app_registration</mat-icon
              >
              Enrollment 
            </span>
          </a>
        </li>
        <li class="nav-item" *ngIf="('robots-list' | checkAccess)">
          <a
            [routerLink]="['/robots-list']"
            routerLinkActive="active"
            #rlaRobot="routerLinkActive"
          >
            <img
              class="icon icon-nav-dashboard"
              src="../../../assets/icons/new_icons/regal_v2{{
                rlaRobot.isActive ? '-white' : ''
              }}.svg"
            />
            <span class="nav-label">Robots</span>
            <span
              class="badge badge-pill"
              role="button"
              tabindex="0"
              (click)="onNavigate($event, '/robots-list/form/new')"
            >
              <span class="material-icons-outlined"> add </span>
            </span>
          </a>
        </li>
        <li class="nav-item" *ngIf="('robots-requests' | checkAccess)">
          <a
            [routerLink]="['/robots-requests']"
            routerLinkActive="active"
            #rlaRobotReq="routerLinkActive"
          >
            <mat-icon class="material-symbols-outlined nav-icon"
              >content_paste_go</mat-icon
            >
            <span class="nav-label">
              <mat-icon class="material-symbols-outlined icon"
                >content_paste_go</mat-icon
              >
              Requests
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- Spacer -->
  <div class="sidebar-spacer"></div>
  <!-- Util -->
  <div class="sidebar-footer"></div>
</div>
