export const deviceTypes = {
    1: "Phone",
    2: "Tablet",
    3: "Notebook",
    4: "Other",
    5: "Desktop"
}

export const customerTypes = {
    "B2C": "PRIVAT",
    "B2B": "BUSINESS",
    "B2B-VAT": "BUSINESS-VAT"
}