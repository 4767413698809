import { Component } from '@angular/core';
import { ScriptLoaderService } from './shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'athena-frontend';

  constructor(private scriptLoadService: ScriptLoaderService,){
  }

  ngOnInit() {
    this.scriptLoadService.loadAssetJiraWidget(() => {})
  }
}
